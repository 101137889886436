import React from "react";
import { Box } from "rebass/styled-components";
import { Container } from "../../../global";
import { graphql, useStaticQuery } from "gatsby";
import { Title, SubTitle, Img, ListContainer, Link } from "./style";
const Saying = () => {
  const data = useStaticQuery(graphql`
    query {
      twitter1: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter1" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      twitter2: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter2" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter3: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter3" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter4: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter4" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter5: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter5" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter6: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter6" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter7: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter7" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter8: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter8" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter9: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter9" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter10: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter10" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twitter11: file(sourceInstanceName: { eq: "product" }, name: { eq: "twitter11" }) {
        childImageSharp {
          fluid(maxWidth: 411) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const list = [
    {
      url: data.twitter1.childImageSharp.fluid,
      href: "https://twitter.com/PoojaMedia/status/1622693856054501376?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter2.childImageSharp.fluid,
      href: "https://twitter.com/its_shaokhan/status/1620119189326548994?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter3.childImageSharp.fluid,
      href: "https://twitter.com/SakasticBET/status/1620100189569814529?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter4.childImageSharp.fluid,
      href: "https://twitter.com/ucheimmortal/status/1622863539177127936?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter5.childImageSharp.fluid,
      href: "https://twitter.com/OgaNlaMedia/status/1621182878225731590?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter6.childImageSharp.fluid,
      href: "https://twitter.com/Mavel_Tee/status/1622941719661629444?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter8.childImageSharp.fluid,
      href: "https://twitter.com/minilordlamba/status/1622950681031188482?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter9.childImageSharp.fluid,
      href: "https://twitter.com/sochimuoneke/status/1622679618900660235?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter10.childImageSharp.fluid,
      href: "https://twitter.com/Skeenyfireman/status/1622889896690999297?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
    {
      url: data.twitter11.childImageSharp.fluid,
      href: "https://twitter.com/SeunOnafowora/status/1621503612936675334?s=20&t=2UtQ7GO83XVsOdnfFdes-A",
    },
  ];
  return (
    <Box
      bg="#F4FCFA"
      mt="135px"
      sx={{
        padding: "66px 0 105px",
      }}
    >
      <Container pd="0">
        <Title>No transaction failure on OPay.</Title>
        <SubTitle>Here&apos;s what our customers think</SubTitle>
        <ListContainer>
          {list.map(item => {
            return (
              <Box
                key={item.url}
                sx={{
                  width: ["100%", "100%", "100%", "411px"],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexShrink: 0,
                }}
              >
                <Link href={item.href} target="_blank" rel="noopener noreferrer">
                  <Img objectFit="contain" fluid={item.url} width="100%" />
                </Link>
              </Box>
            );
          })}
        </ListContainer>
      </Container>
    </Box>
  );
};

export default Saying;
