import React from "react";
import { Container } from "../../../global";
import { Box } from "rebass/styled-components";
// import securityUrl from "../../../../images/product/security.png";
import { SecurityWrapper, Title, Flex, Card, CardTitle, CardContent, Image } from "./style";
import { graphql, useStaticQuery } from "gatsby";

const Security = () => {
  const data = useStaticQuery(graphql`
    query {
      securityImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "security2" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <SecurityWrapper>
      <Container pd="0">
        <Title>Your Security and Privacy is our priority</Title>
        <Flex>
          <Box>
            <Card>
              <CardTitle>Advanced Fraud Detection</CardTitle>
              <CardContent>
                Your data is stored and encrypted using strong cryptography. We scan our systems daily against security
                threats.
              </CardContent>
            </Card>
            <Card>
              <CardTitle>Safe Transactions</CardTitle>
              <CardContent>
                We are compliant with PCI DSS. Your transactions are always encrypted and secured.
              </CardContent>
            </Card>
          </Box>
          {/* <Image objectFit="contain" fluid={data.securityImage.childImageSharp.fluid} width="100%" /> */}
          <Image objectFit="contain" fluid={data.securityImage.childImageSharp.fluid} />
        </Flex>
      </Container>
    </SecurityWrapper>
  );
};

export default Security;
