import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Box, Flex, Text, Heading } from "rebass/styled-components";
import { Container } from "../../global";

import Phones from "../../../images/product/opay-app.png";
import QRCode from "../../../images/product/new-qr-code.svg";
import { useLocationCountry } from "../../../hooks";
import Img from "gatsby-image";

const DownloadAppSection = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          playstoreUrl
          applestoreUrl
        }
      }

      phone: file(sourceInstanceName: { eq: "product" }, name: { eq: "opay-app" }) {
        childImageSharp {
          fluid(maxWidth: 741) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { isPakistan } = useLocationCountry();

  return (
    <WrapperContainer>
      <Container>
        <FlexWrapper>
          <ImageWrapper objectFit="contain" fluid={data.phone.childImageSharp.fluid} />
          <StyledTextWrapper>
            <HeadingWrapper variant="heading" maxWidth="58rem" alignSelf="baseline">
              Download the app and start your journey to stress-free payments now
            </HeadingWrapper>
            {!isPakistan && (
              <Flex alignItems="center" width="100%" px={["0", "60px", "0px", "0px"]}>
                <StyledSVG>
                  <QRCode />
                </StyledSVG>
                <Flex flexDirection="column" px={["10px", 0, "30px", "30px"]} justifyContent="start">
                  <Text
                    variant="body"
                    textAlign={["left", null, null, null, null]}
                    sx={{ whiteSpace: ["none", "none", null, "none", "none"] }}
                  >
                    {"Use your phones camera to scan and download \n the OPay app"}
                  </Text>
                  <Text variant="body" fontWeight="700" pt={10} alignSelf={["baseline", null, null, null]}>
                    Available on <CTA href={data.site.siteMetadata.applestoreUrl}>iOS</CTA> and{" "}
                    <CTA href={data.site.siteMetadata.playstoreUrl}>Android</CTA>
                  </Text>
                </Flex>
              </Flex>
            )}
          </StyledTextWrapper>
        </FlexWrapper>
      </Container>
    </WrapperContainer>
  );
};

export default DownloadAppSection;

const CTA = styled(OutboundLink)`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledSVG = styled.div`
  // background-color: ${({ theme }) => theme.colors.white.regular};
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;

  svg {
    width: 100px;
    height: 100px;
  }

  ${({ theme }) => theme.mq.md`
  width: 100px;
  height: 100px;

  svg {
    width: 100px;
    height: 100px;
  }
  `}

  ${({ theme }) => theme.mq.lg`
  width: 163px;
  height: 154px;

   svg {
    width: 163px;
    height: 154px;
  }
  `}

  ${({ theme }) => theme.mq.xl`
    width: 163px;
  height: 154px;

   svg {
     width: 163px;
     height: 154px;
  }
  `}
`;

const StyledTextWrapper = styled.div`
  word-wrap: break-word;
  padding-bottom: 20px;
  ${({ theme }) => theme.mq.md`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0;
    width: 70%;
    flex-shrink: 0;
    padding-bottom: 0;
  `}

  ${({ theme }) => theme.mq.lg`
    margin-left: -80px;
     width: 545px;
     padding-bottom: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-left: -100px;
    width: 545px;
    padding-bottom: 0;
  `}
`;

const FlexWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  /* padding: 20rem 0; */
  margin: 30px 0 60px 0;
  ${({ theme }) => theme.mq.md`
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  `}

  ${({ theme }) => theme.mq.lg`
    align-items: center;
    flex-direction: row;
    height: 550px;
    justify-content: flex-start;
  `}
`;

const ImageWrapper = styled(Img)`
  width: 100%;
  padding-top: 1rem;

  ${({ theme }) => theme.mq.md`
    width: 100%;
    display: flex;
  `}

  ${({ theme }) => theme.mq.lg`
    width: 50%;
    display: flex;
    flex-shrink: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    width: 741px;
    display: flex;
    align-self: flex-end;
    flex-shrink: 0;

  `}
`;

const HeadingWrapper = styled(Heading)`
  text-align: center;
  margin-bottom: 20px;
  color: #210F60;
  padding: 10px 0;

  ${({ theme }) => theme.mq.md`
    margin-bottom: 10px;
    font-size: 25px;
    text-align: left;
  `}

  ${({ theme }) => theme.mq.lg`
    font-size: 30px;
    //  white-space: pre-line;
     width: 545px;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-bottom: 10px;
    text-align: left;
    width: 545px;
    font-size: 40px;
    line-height: 55px;
  `}
`;

const WrapperContainer = styled.div`
  width: 100%;
  background: #F4FCFA;
  ${({ theme }) => theme.mq.md`
    // height: 550px;
  `}

  ${({ theme }) => theme.mq.lg`
    height: 550px;
  `}

  ${({ theme }) => theme.mq.xl`
    height: 550px;
  `}

`;
