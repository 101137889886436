import React from "react";
import { Container } from "../../../global";
import { ServicesContainer, Flex, Title, Content, Img, CommonContainer } from "./style";
import service1 from "../../../../images/product/service1.png";
import service2 from "../../../../images/product/service2.png";
import Fade from "react-reveal/Fade";
import { graphql, useStaticQuery } from "gatsby";
const services = () => {
  const data = useStaticQuery(graphql`
    query {
      service1: file(sourceInstanceName: { eq: "product" }, name: { eq: "service1" }) {
        childImageSharp {
          fluid(maxWidth: 756) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      service2: file(sourceInstanceName: { eq: "product" }, name: { eq: "service2" }) {
        childImageSharp {
          fluid(maxWidth: 536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Container>
      <ServicesContainer>
        <Title>Our Services</Title>
        <Flex>
          <Img objectFit="contain" fluid={data.service2.childImageSharp.fluid} />
          <CommonContainer pr="80px" width="480px">
            <Fade bottom delay={250}>
              <Title>Fund your Account, Make Transfers, Pay Bills</Title>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="46px">
                Live life on your own terms! Add money to your OPay wallet and transfer to other bank accounts for free.
                Enjoy bonuses on airtime & data top-ups and fast bill payments at no extra charge.
              </Content>
            </Fade>
          </CommonContainer>
        </Flex>
        {/* <Flex direction="reverse">
          <CommonContainer pl="77px" width="425px">
            <Fade bottom delay={250}>
              <Title>Flexible Savings</Title>
            </Fade>
            <Fade bottom delay={500}>
              <Content mt="44px">
                Need a better way to save? Enjoy up to 15% annual interest paid daily when you save with OPay and spend
                without paying withdrawal charges.
              </Content>
              <Content mt="51px" color="#515152">
                Powered by BlueRidge Microfinance Bank.
              </Content>
            </Fade>
          </CommonContainer>
          <Img objectFit="contain" fluid={data.service1.childImageSharp.fluid} />
        </Flex> */}
      </ServicesContainer>
    </Container>
  );
};

export default services;
