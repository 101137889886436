import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Fade from "react-reveal/Fade";
import { ContainerCustom } from "../../../global";
import whiteStar from "../../../../images/product/whiteStar.png";
import blueStar from "../../../../images/product/blueStar.png";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import "./style.css";

import { Item, InnerBlock, Star, Title, Content, Flex, CommonImg } from "./style";
const List = () => {
  return (
    <ContainerCustom id="home-list" pd="0 50px" position="relative">
      <Flex>
        <SwiperList />
      </Flex>
    </ContainerCustom>
  );
};

const SwiperList = () => {
  const [num, setNum] = useState(null);
  const data = useStaticQuery(graphql`
    query {
      transferImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "transfer" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      owealthImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "owealth" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      cardsImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "cards" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      openingImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "opening" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      airtimeImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "airtime2" }) {
        childImageSharp {
          fluid(maxWidth: 373, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const listArr = [
    {
      title: "Bank Transfers",
      content: "Fast transfers with 100% success rate to all banks across Nigeria, anytime for free.",
      url: data.transferImage.childImageSharp.fluid,
    },
    // {
    //   title: "Flexible Savings",
    //   content:
    //     "Up to 15% annual interest on flexible savings with daily interest and unlimited withdrawals at no cost (Powered by Blueridge MFB).",
    //   url: data.owealthImage.childImageSharp.fluid,
    // },
    {
      title: "Instant and Free Debit Cards",
      content:
        "Apply and get a card instantly at select OPay agents or apply via the OPay App. Enjoy 10 free ATM withdrawals monthly and zero maintenance fee.",
      url: data.cardsImage.childImageSharp.fluid,
    },
    {
      title: "Amazing Bonuses",
      content:
        "All new users can enjoy an amazing welcome bonus while all users can enjoy cashback on every airtime and data recharge.",
      url: data.airtimeImage.childImageSharp.fluid,
    },
    {
      title: "Easy Account Opening",
      content: "Instantly open an OPay account with just your phone number, NIN, BVN or other valid IDs.",
      url: data.openingImage.childImageSharp.fluid,
    },
  ];
  return (
    <Swiper
      className="mySwiper"
      spaceBetween={30}
      slidesPerView={1}
      navigation={true}
      modules={[Navigation]}
      breakpoints={{
        992: {
          //当屏幕宽度大于等于992
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1400: {
          //当屏幕宽度小于等于1400
          slidesPerView: 3,
          spaceBetween: 36,
        },
      }}
    >
      {listArr.map((item, index) => {
        return (
          <SwiperSlide key={index} onMouseOver={() => setNum(index)} onMouseOut={() => setNum(null)}>
            <Item>
              <InnerBlock className={num === index ? "item-block-active" : "item-block"}>
                <Star src={num === index ? whiteStar : blueStar}></Star>
                <Fade bottom delay={250}>
                  <Title className="title">{item.title}</Title>
                </Fade>
                <Fade bottom delay={500}>
                  <Content className="content">{item.content}</Content>
                </Fade>
                <CommonImg fluid={item.url} />
              </InnerBlock>
            </Item>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default List;
