import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Fade from "react-reveal/Fade";
import { Container } from "../../../global";
import { useLocationCountry } from "../../../../hooks";
import HeroSection from "../../hero";
import {
  StyledBackgroundSection,
  HeaderWrapper,
  HeaderTextGroup,
  Flex,
  StyledMainImage,
  StyledVectorImage,
  StyledFrameImage,
  StyledContainer,
  StyledHeaderContainer,
  StyledHeaderBg,
  SubTitleTop,
  StyledFrameContainer,
} from "./style";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          playstoreUrl
          applestoreUrl
        }
      }

      headerMockup: file(sourceInstanceName: { eq: "product" }, name: { eq: "group-header" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dottedCircle: file(sourceInstanceName: { eq: "product" }, name: { eq: "dotted-circle" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      circle: file(sourceInstanceName: { eq: "product" }, name: { eq: "circle" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      vector: file(sourceInstanceName: { eq: "product" }, name: { eq: "vector" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      frame: file(sourceInstanceName: { eq: "product" }, name: { eq: "frame" }) {
        childImageSharp {
          fluid(maxWidth: 388) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      maskBg: file(sourceInstanceName: { eq: "product" }, name: { eq: "mask-bg" }) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { isPakistan } = useLocationCountry();

  return (
    <StyledBackgroundSection>
      <HeaderWrapper id="top">
        <StyledContainer position="absolute">
          <StyledHeaderBg objectFit="contain" fluid={data.maskBg.childImageSharp.fluid}></StyledHeaderBg>
        </StyledContainer>
        <Container>
          <Flex>
            <HeaderTextGroup>
              <Fade bottom delay={250}>
                <HeroSection.Title color="purple">
                  We are {""}
                  <HeroSection.Hightlighted>
                    Beyond Banking
                    <StyledVectorImage
                      objectFit="contain"
                      fluid={data.vector.childImageSharp.fluid}
                      width="268px"
                      height="15px"
                    />
                  </HeroSection.Hightlighted>
                </HeroSection.Title>
              </Fade>

              <Fade bottom delay={500}>
                <SubTitleTop>OPay gives you the freedom to get more.</SubTitleTop>
                <SubTitleTop>
                  Our solutions make payments easier, transfers free, savings more rewarding and gives you cashback on
                  airtime and data top-ups. With 100% network uptime, you can make payments in seconds without
                  transaction failure. Enjoy a better life with OPay.
                </SubTitleTop>
              </Fade>

              <StyledFrameContainer>
                <StyledFrameImage objectFit="contain" fluid={data.frame.childImageSharp.fluid} />
              </StyledFrameContainer>

              {isPakistan ? null : <HeroSection.AppButtons />}
            </HeaderTextGroup>
            <StyledHeaderContainer>
              <StyledMainImage objectFit="contain" fluid={data.headerMockup.childImageSharp.fluid} />
            </StyledHeaderContainer>
          </Flex>
        </Container>
      </HeaderWrapper>
    </StyledBackgroundSection>
  );
};

export default Header;
