import styled from "styled-components";
import Img from "gatsby-image";

const WrapperContainer = styled.div``;
const ImageContainer = styled.div`
  text-align: center;
`;
const ServicesContainer = styled.div`
  padding-top: 53px;
  background: #f4fcfa;
`;
const Flex = styled.div`
  display: flex;
  justify-content: center;
`;
const Content = styled.div`
  color: #515152;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 32px;
  max-width: 900px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.md`
    font-size: 22px;
  `}
`;
const Image = styled(Img)`
  width: 90%;
  margin-top: 20px;
  margin: 0 auto;
`;
const FontContainer = styled.div`
  padding-top: 4rem;
  padding-bottom: 6rem;
  width: 100%;
  ${({ theme }) => theme.mq.md`
    padding-top: 162px;
    padding-bottom: 166px;
    width: 100%;
    margin: 0 auto;
  `}
  ${({ theme }) => theme.mq.lg`
    padding-top: 162px;
    padding-bottom: 166px;
    width: 100%;
    margin: 0 auto;
  `}
  ${({ theme }) => theme.mq.xl`
    padding-top: 162px;
    padding-bottom: 166px;
    width: 1076px;
    margin: 0 auto;
  `}
`;
const Label = styled.div`
  opacity: 1;
  color: #1dc99b;
  font-size: 22px;
  font-weight: 600;
  margin-top: 36px;
  text-align: center;
`;

export { ServicesContainer, Content, Image, FontContainer, WrapperContainer, ImageContainer, Label };
