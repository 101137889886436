import styled, { keyframes } from "styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Img from "gatsby-image";

const phoneBounce = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform:  translateY(5px);
}
100% {
  transform:  translateY(0);
}
`;

const cardBounce = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform: translateY(5px);
}
100% {
  transform: translateY(0);
}
`;

const StyledBackgroundSection = styled.div`
  height: 950px;
  position: relative;
  width: 100%;
  z-index: 4;

  ${({ theme }) => theme.mq.md`
  height: 1100px;
  // height: 650px;
  `}
  ${({ theme }) => theme.mq.lg`
  height: 700px;
  `}
  ${({ theme }) => theme.mq.xl`
  height: 700px;
  `}
`;

const HeaderWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.mq.lg`
  height: 100%;
  `}
`;

const HeaderTextGroup = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.header};
  display: flex;
  flex-direction: column;
  z-index: 2;

  ${({ theme }) => theme.mq.lg`
    padding: 0 0 0 0;
    align-items: flex-start;
    flex-basis: 60%;
  `}

  ${({ theme }) => theme.mq.xl`
    flex-basis: 51%;
    align-items: flex-start;
  `}
`;

const DownloadButtonWrapper = styled.div`
  display: flex;
  margin: 0 auto;

  ${({ theme }) => theme.mq.lg`
  justify-self: flex-start;
    margin: 0;
  `}
`;

const Flex = styled.div`
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 180px;

  ${({ theme }) => theme.mq.lg`
  flex-direction: row;
    padding: 210px 0 0 0;
    // padding-bottom: 80px;
    padding-bottom: 20px;
  `}
`;

const ImageWrapper = styled.div`
  /* filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.5)); */
  margin-top: 5rem;
  position: relative;

  ${({ theme }) => theme.mq.lg`
  display: flex;
    flex-basis: 55%;
    flex: 1;
    margin-top: 0;
  `}

  ${({ theme }) => theme.mq.xl`
  flex-basis: 65%;
  `}
`;

const StyledImageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 310px;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.md`
  max-width: 355px;
  `}

  ${({ theme }) => theme.mq.lg`
  max-width: 100%;
  `}

  ${({ theme }) => theme.mq.xl`
  margin-top: -60px;  
  `}
`;

const GroupedImages = styled.div`
  height: 380px;
  margin-bottom: -70px;
  padding-left: 20px;
  position: relative;
  width: 100%;

  ${({ theme }) => theme.mq.md`
  height: 480px;
  `}

  ${({ theme }) => theme.mq.lg`
  padding-left: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    height: auto;
    padding-left: 80px;
  `}
`;

const StyledMainImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;

  ${({ theme }) => theme.mq.sm`

  `}

  ${({ theme }) => theme.mq.md`
  `}

  ${({ theme }) => theme.mq.lg`
    top: -50px;
    left: 0;
  `}

  ${({ theme }) => theme.mq.xl`
    top: -60px;
    left: -40px;
  `}
`;

// circle
const StyledAddMoneyImage = styled(Img)`
  animation: ${phoneBounce} 4s infinite forwards;
  position: absolute !important;
  top: 0px;
  width: 20px;
  left: 80px;

  ${({ theme }) => theme.mq.sm`
    top: 0px;
    left: 60px;
  `}


  ${({ theme }) => theme.mq.md`
     width: 20px;
     top: 0px;
     left: 60px;
  `}
  ${({ theme }) => theme.mq.lg`
     width: 20px;
     top: 40px;
     left: 60px;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-bottom: 0;
    top: 80px;
    left: 220px;
    width: 20px;
  `}
`;

// dotted cirlce
const StyledRequestImage = styled(Img)`
  animation: ${cardBounce} 3s infinite forwards;
  left: 20px;
  position: absolute !important;
  top: 250px;
  width: 50px;


   ${({ theme }) => theme.mq.sm`
   left: -20px;
   top: 320px;
  `}


  ${({ theme }) => theme.mq.md`
    left: -20px;
    top: 360px;
  `}

  ${({ theme }) => theme.mq.lg`
    top: 50px
    left: 0px;
    width: 50px;
  `}

  ${({ theme }) => theme.mq.xl`
    margin-bottom: 0;
    left: 150px;
    top: 480px;
    width: 50px;
  `}

`;

const CTA = styled(OutboundLink)`
  svg {
    width: 128px;
    height: 42px;
  }

  ${({ theme }) => theme.mq.md`
  svg {
    width: 179px;
    height: 60px;
  }
  `}

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

// vector
const StyledVectorImage = styled(Img)`
  // left: 50%;
  // bottom: -30px;
  // transform: translateX(-50%);
  // position: absolute !important;
  // bottom: 0;
  height: 15px;
  width: 268px;
  display: none;
  margin-left: 220px;
  margin-top: 10px;
  ${({ theme }) => theme.mq.md`
    display: block;
  `}
  ${({ theme }) => theme.mq.lg`
    display: none;
  `}

  ${({ theme }) => theme.mq.xl`
    display: block;
  `}
`;

// frame
const StyledFrameImage = styled(Img)`
  width: 100%;
`;

const StyledContainer = styled.div`
  position: ${props => (props.position ? props.position : "relative")};
  width: ${props => (props.width ? props.width : "100%")};
  height: ${props => props.height};
  top: 0;
  left: 0;
  margin-bottom: ${props => (props.mb ? props.mb : "2em")};
`;

const StyledHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: -30px auto;
  ${({ theme }) => theme.mq.md`
    width: 543px;
    height: 543px;
    margin: 0 auto;
  `}
  ${({ theme }) => theme.mq.lg`
    width: 543px;
    height: 543px;
    margin: 0 auto;
  `}
  ${({ theme }) => theme.mq.xl`
    width: 620px;
    height: 620px;
    margin: 0 auto;
  `}
`;

const StyledHeaderBg = styled(Img)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;
const SubTitleTop = styled.div`
  color: #210f60;
  line-height: 30px;
  font-size: 20px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.mq.lg`
    width: 400px;
    text-align: left;
  `}
  ${({ theme }) => theme.mq.xl`
    width: 500px;
    text-align: left;
  `}
`;
const StyledFrameContainer = styled.div`
  margin-top: 3em;
  margin-bottom: 3em;
  width: 100%;
  height: 24px;
  ${({ theme }) => theme.mq.lg`
    width: 388px;
  `}
  ${({ theme }) => theme.mq.xl`
    width: 388px;
  `}
`;

export {
  StyledBackgroundSection,
  HeaderWrapper,
  HeaderTextGroup,
  DownloadButtonWrapper,
  Flex,
  CTA,
  StyledMainImage,
  ImageWrapper,
  StyledImageWrapper,
  StyledAddMoneyImage,
  StyledRequestImage,
  GroupedImages,
  StyledVectorImage,
  StyledFrameImage,
  StyledContainer,
  StyledHeaderContainer,
  StyledHeaderBg,
  SubTitleTop,
  StyledFrameContainer,
};
