import styled from "styled-components";
import Image from "gatsby-image";
const ServicesContainer = styled.div`
  padding-top: 42px;
  padding-bottom: 40px;
  ${({ theme }) => theme.mq.md`
    padding-bottom: 149px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding-bottom: 149px;
  `}
`;
const Flex = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction === "reverse" ? "column-reverse" : "column")};
  align-items: center;
  margin-top: 60px;
  position: relative;
  ${({ theme }) => theme.mq.lg`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 21px;
  `}
  ${({ theme }) => theme.mq.xl`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 21px;
  `}
`;
const Title = styled.div`
  color: #210f60;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  line-height: 48px;
  ${({ theme }) => theme.mq.lg`
    text-align: left;
    margin-left: ${props => (props.ml ? props.ml : 0)}
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: left;
  `}
`;

const Content = styled.div`
  color: ${props => (props.color ? props.color : "#515152")};
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 32px;
  ${({ theme }) => theme.mq.lg`
    text-align: left;
    margin-top: ${props => (props.mt ? props.mt : 0)}
  `}
  ${({ theme }) => theme.mq.xl`
    text-align: left;
    margin-top: ${props => (props.mt ? props.mt : 0)}
  `}
`;

const Img = styled(Image)`
  width: 100%;
  ${({ theme }) => theme.mq.lg`
    width: 50%;
  `}
  ${({ theme }) => theme.mq.xl`
    width: ${props => props.width};
    position: relative;
  `}
`;

const CommonContainer = styled.div`
  margin-top: ${props => (props.mt ? props.mt : 0)};
  ${({ theme }) => theme.mq.lg`
    padding-right: ${props => (props.pr ? props.pr : 0)};
    width: ${props => (props.width ? props.width : "100%")};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    margin-top: 0;
  `}
  ${({ theme }) => theme.mq.xl`
    padding-right: ${props => (props.pr ? props.pr : 0)};
    width: ${props => (props.width ? props.width : "100%")};
    padding-left: ${props => (props.pl ? props.pl : 0)};
    margin-top: 0;
  `}
`;

export { ServicesContainer, Flex, Title, Content, Img, CommonContainer };
