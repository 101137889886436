import React, { useState, useEffect } from "react";
import { ContainerBig } from "../../../global";
import HeroSection from "../../hero";
// import debitCards from "../../../../images/product/debit-cards.png";
import Fade from "react-reveal/Fade";
import { graphql, useStaticQuery } from "gatsby";
import { WrapperContainer, ServicesContainer, Content, Image, FontContainer, Label, ImageContainer } from "./style";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Cards = () => {
  const data = useStaticQuery(graphql`
    query {
      debitCardImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "debit-cards" }) {
        childImageSharp {
          fluid(maxWidth: 1111) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          playstoreUrl
          applestoreUrl
        }
      }
    }
  `);
  const [platform, setPlatform] = useState("unknown");

  function getPlatform() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setPlatform("android");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setPlatform("ios");
    } else setPlatform("unknown");
  }

  useEffect(() => {
    getPlatform();
  }, []);
  return (
    <WrapperContainer>
      <ServicesContainer>
        <ContainerBig>
          <Fade bottom delay={250}>
            <HeroSection.Title color="purple" textAlign="center">
              OPay Debit Cards, {""}
              <HeroSection.Hightlighted>Your Passport To The World</HeroSection.Hightlighted>
            </HeroSection.Title>
          </Fade>
          <Fade bottom delay={500}>
            <Content>
              Get your OPay Debit card at select OPay agent stores instantly or apply on the OPay App and enjoy 10 free
              ATM withdrawals monthly and zero card maintenance fees. The OPay debit card is accepted at Any ATM, POS or
              Web.
            </Content>
          </Fade>
          <Image objectFit="contain" fluid={data.debitCardImage.childImageSharp.fluid} width="90%" alt="OPay Card" />
        </ContainerBig>
      </ServicesContainer>
      <FontContainer>
        <ContainerBig>
          <HeroSection.Title color="purple" textAlign="center">
            Join 35+ million users who already trust us with their money
          </HeroSection.Title>
          <Label>
            <OutboundLink
              href={platform === "ios" ? data.site.siteMetadata.applestoreUrl : data.site.siteMetadata.playstoreUrl}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: "none",
              }}
            >
              Get started
            </OutboundLink>
          </Label>
        </ContainerBig>
      </FontContainer>
    </WrapperContainer>
  );
};

export default Cards;
