import React from "react";
import { Box, Image, Heading, Text, Flex, Link } from "rebass/styled-components";
import { ContainerBig } from "../../../global";
// import HelpUrl from "../../../../images/product/help.png";
import phoneUrl from "../../../../images/product/new-phone.png";
import msgUrl from "../../../../images/product/new-msg.png";
import { graphql, useStaticQuery } from "gatsby";
import { BigImage } from "./style";

const Help = () => {
  const data = useStaticQuery(graphql`
    query {
      HelpImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "help" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box width={[1]} mt={["50px", 0]} mb={["20px", null, null, 0, 0]}>
      <ContainerBig pd="0">
        <Box
          sx={{
            position: "relative",
            padding: ["0", null, null, "134px 0 171px"],
          }}
        >
          <Box
            bg="#210F60"
            sx={{
              borderRadius: "30px",
              padding: ["16px", "32px", "32px", "113px 0 152px 60px"],
            }}
          >
            <Flex
              sx={{
                flexDirection: ["column-reverse", "column-reverse", "column-reverse", "row", "row"],
                alignItems: ["center"],
              }}
            >
              <Box
                sx={{
                  width: ["100%", null, null, "400px", "573px"],
                }}
              >
                <Heading
                  sx={{
                    fontSize: ["52px"],
                    fontWeight: 600,
                    lineHeight: "64px",
                    color: "#fff",
                    textAlign: ["center", null, null, "left"],
                  }}
                >
                  We are here to help!
                </Heading>
                <Text
                  sx={{
                    fontSize: ["18px"],
                    fontWeight: 400,
                    lineHeight: "32px",
                    color: "#fff",
                    marginTop: ["35px"],
                    textAlign: ["center", null, null, "left"],
                  }}
                >
                  Chat with us via our in-app live chat or send us a message on our official social media pages. If you
                  require further assistance, contact us via the following channels:
                </Text>
                <Flex
                  sx={{
                    marginTop: "48px",
                  }}
                >
                  <Image
                    src={phoneUrl}
                    sx={{
                      width: "28px",
                      height: "28px",
                      marginTop: "4px",
                    }}
                  ></Image>
                  <Box ml={"10px"}>
                    <Flex
                      sx={{
                        flexWrap: "wrap",
                      }}
                    >
                      <Link
                        href={"tel:07008888328"}
                        sx={{
                          textDecoration: "underline",
                          color: "#1DC99B",
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        0700 8888 328{" "}
                      </Link>
                      <Text
                        color="#fff"
                        mx={"4px"}
                        sx={{
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        OR{" "}
                      </Text>
                      <Link
                        href={"tel:02018888328"}
                        sx={{
                          textDecoration: "underline",
                          color: "#1DC99B",
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        020 18888 328
                      </Link>
                      <Text
                        color="#fff"
                        sx={{
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        &nbsp;(App & Card queries)
                      </Text>
                    </Flex>
                    <Flex
                      sx={{
                        flexWrap: "wrap",
                      }}
                    >
                      <Link
                        href={"tel:07008888329"}
                        sx={{
                          textDecoration: "underline",
                          color: "#1DC99B",
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        0700 8888 329{" "}
                      </Link>
                      <Text
                        color="#fff"
                        mx={"4px"}
                        sx={{
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        OR{" "}
                      </Text>
                      <Link
                        href={"tel:02018888329"}
                        sx={{
                          textDecoration: "underline",
                          color: "#1DC99B",
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        020 18888 329
                      </Link>
                      <Text
                        color="#fff"
                        sx={{
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        &nbsp;(POS Business queries)
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
                {/* <Flex>
                  <Image
                    src={msgUrl}
                    sx={{
                      width: "28px",
                      height: "21px",
                      marginTop: "4px",
                    }}
                  ></Image>
                  <Box ml={"10px"}>
                    <Flex>
                      <Link
                        href={"mailto:customerservice@opay-inc.com"}
                        sx={{
                          textDecoration: "underline",
                          color: "#1DC99B !important",
                          fontSize: "18px",
                          lineHeight: "32px",
                          fontWeight: 400,
                        }}
                      >
                        customerservice@opay-inc.com
                      </Link>
                    </Flex>
                  </Box>
                </Flex> */}
              </Box>
              <BigImage objectFit="contain" fluid={data.HelpImage.childImageSharp.fluid} />
            </Flex>
          </Box>
        </Box>
      </ContainerBig>
    </Box>
  );
};

export default Help;
