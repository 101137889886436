import styled from "styled-components";
import HeroSection from "../../hero";
import Img from "gatsby-image";

const Item = styled.div`
  width: 90%;
  position: relative;
  padding-top: 31.8rem;
  flex-shrink: 0;
  max-width: 373px;
  z-index: 10;
  margin: 0 auto;
  ${({ theme }) => theme.mq.sm`
    padding-top: 318px;
    width: 373px;
    height: 719px;
    &:not(:last-child) {
      margin-right: 0;
    }
    margin: 0 auto;
  `}
  ${({ theme }) => theme.mq.md`
    padding-top: 318px;
    width: 373px;
    height: 719px;
    &:not(:last-child) {
      margin-right: 0;
    }
  `}
  ${({ theme }) => theme.mq.lg`
    padding-top: 318px;
    margin-top: 0;
    width: 373px;
    height: 719px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  `}
  ${({ theme }) => theme.mq.xl`
    margin-top: 0;
    width: 373px;
    height: 719px;
    padding-top: 318px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  `}
`;

const InnerBlock = styled.div`
  width: 100%;
  padding: 101px 20px 20px;
  // background-color: ${props => (props.bg === "accent" ? props.theme.colors.accent : "#F8FBFF")};
  border-radius: 16px;
  ${({ theme }) => theme.mq.lg`
     padding: 101px 40px 0;
     height: 400px;
  `}
  ${({ theme }) => theme.mq.xl`
    padding: 101px 40px 0;
    height: 400px;
  `}
`;
const Container = styled.div``;

const Section = styled.section`
  overflow: hidden;
  background-color: ${props => (props.bg ? props.bg : "#fff")};
  width: 311px;
`;

const Star = styled.img`
  width: 30px;
  height: 30px;
`;
const BigImg = styled(Img)`
  position: absolute !important;
  width: 111%;
  // right: -25%;
  right: -28%;
  bottom: 22%;
  ${({ theme }) => theme.mq.sm`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.md`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.lg`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.xl`
    bottom: 22%;
  `}
`;

const CommonImg = styled(Img)`
  width: 100%;
  right: -25%;
  bottom: 24%;
  position: absolute !important;
  z-index: 5;
  ${({ theme }) => theme.mq.md`
    bottom: 24%;
  `}
  ${({ theme }) => theme.mq.lg`
    bottom: 22%;
  `}
  ${({ theme }) => theme.mq.xl`
    bottom: 22%;
  `}
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  // color: ${props => (props.color ? props.color : "#fff")};
  margin: 80px 0 19px;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  // color: ${props => (props.color ? props.color : "#fff")};
`;

const Flex = styled.div`
  padding: 20px 0 30px;
  ${({ theme }) => theme.mq.md`
    align-items: center;
    padding: 80px 0 30px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding: 80px 0 100px;
  `}

  ${({ theme }) => theme.mq.xl`
    padding: 100px 0 100px;
  `}
`;

export { Container, Item, InnerBlock, Section, Star, Title, Content, BigImg, CommonImg, Flex };
