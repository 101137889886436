import styled from "styled-components";
import Img from "gatsby-image";

const BigImage = styled(Img)`
  top: 0;
  ${({ theme }) => theme.mq.sm`
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
  `}

  ${({ theme }) => theme.mq.md`
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
  `}

  ${({ theme }) => theme.mq.lg`
    width: 40%;
    position: absolute !important;
    right: 20px;
    top: 100px;
  `}


  ${({ theme }) => theme.mq.xl`
    width: 494px;
    position: absolute !important;
    right: 60px;
    top: 1px;
  `}
`;

export { BigImage };
