import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import Header from "../components/sections/home/header";
// import Footer from "../components/sections/footer";
// import SecurityAndPrivacy from "../components/sections/home/security-and-privacy";

import ArrowIndicator from "../components/sections/home/arrow";
// import DoMore from "../components/sections/home/doMore";
// import Inquiries from "../components/sections/home/inquiries";
// import BetterLife from "../components/sections/better-life";
// import OurPartners from "../components/sections/home/our-partners";
// import OpayCard from "../components/sections/home/opay-card";
import List from "../components/sections/home/list";
import OurServices from "../components/sections/home/our-services";
import Cards from "../components/sections/home/debit-cards";
import DownloadAppSection from "../components/sections/home/download-app";
import Security from "../components/sections/home/security";
import Saying from "../components/sections/home/saying";
import Help from "../components/sections/home/help";
// import NewFooter from "../components/sections/home/new-footer";
import Footer from "../components/sections/footer/index";

const IndexPage = () => (
  <Layout>
    <SEO title="OPay" />
    <Navigation background="white" />
    <Header />
    <ArrowIndicator />
    <List />
    <OurServices />
    <Cards />
    <DownloadAppSection />
    <Saying />
    <Security />
    <Help />
    <Footer />
  </Layout>
);

export default IndexPage;
