import styled from "styled-components";
import Img from "gatsby-image";

const SecurityWrapper = styled.div`
  padding: 0 0 80px;
  ${({ theme }) => theme.mq.md`
    padding: 0px 0 80px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding: 206px 0 157px;
  `}
`;
const Title = styled.div`
  font-size: 3.6rem;
  padding: 0 16px;
  line-height: 4.4rem;
  font-weight: 700;
  text-align: center;
  padding-top: 2rem;
  ${({ theme }) => theme.mq.lg`
      color: #210F60;
      font-size: 52px;
      font-weight: 700;
      line-height: 64px;
      margin-bottom: 2px;
      width: 550px;
      text-align: left;
      padding-top: 0;

  `}
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mq.lg`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:row;
  `}
`;

const Card = styled.div`
  background: rgba(29,201,155,0.05);
  margin: 20px;
  border-radius: 10px;
  padding: 10px;
  ${({ theme }) => theme.mq.md`
    padding: 40px;
    background: rgba(29,201,155,0.05);
    border-radius: 30px;
    box-sizing: border-box;
    margin-top: 30px;
  `}
  ${({ theme }) => theme.mq.lg`
    padding: 40px;
    background: rgba(29,201,155,0.05);
    border-radius: 30px;
    width: 80%;
    box-sizing: border-box;
    margin-top: 30px;
  `}
  ${({ theme }) => theme.mq.xl`
    padding: 40px;
    background: rgba(29,201,155,0.05);
    border-radius: 30px;
    width: 555px;
    box-sizing: border-box;
    margin-top: 30px;

  `}
`;
const CardTitle = styled.div`
  color: rgba(33, 15, 96, 1);
  text-align: center;
  font-weight: 600;
  font-size: 2.2rem;
  ${({ theme }) => theme.mq.lg`
    color: rgba(33,15,96,1);
    font-size: 22px;
    font-weight: 600;
    text-align: left;
  `}
  ${({ theme }) => theme.mq.md`
    color: rgba(33,15,96,1);
    font-size: 22px;
    font-weight: 600;
    text-align: left;
  `}
`;
const CardContent = styled.div`
  text-align: center;
  color: rgba(33, 15, 96, 0.7);
  font-size: 1.6rem;
  padding: 0 5px;
  ${({ theme }) => theme.mq.lg`
    color: rgba(33,15,96,0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    padding: 0;
  `}
  ${({ theme }) => theme.mq.md`
    color: rgba(33,15,96,0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    padding: 0;
  `}
`;

const Image = styled(Img)`

  ${({ theme }) => theme.mq.sm`
      width: 100%;
  `}

  ${({ theme }) => theme.mq.md`
      width: 100%;
      // padding: 0 16px;
  `}

  ${({ theme }) => theme.mq.lg`
      width: 50%;
      // padding: 0 16px;
  `}


  ${({ theme }) => theme.mq.xl`
    width:600px;
    // padding: 0 16px;
  `}
`;

export { SecurityWrapper, Title, Flex, Card, CardTitle, CardContent, Image };
