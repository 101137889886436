import styled from "styled-components";
import Image from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Title = styled.div`
  font-size: 3.6rem;
  padding: 0 16px;
  line-height: 4.4rem;
  font-weight: 700;
  text-align: center;
  padding-top: 2rem;
  color: #210f60;
  ${({ theme }) => theme.mq.lg`
      font-size: 52px;
      font-weight: 700;
      line-height: 64px;
      margin-bottom: 2px;
      text-align: left;
      padding-top: 0;

  `}
`;

const SubTitle = styled.div`
  font-size: 2.6rem;
  padding: 0 16px;
  line-height: 3.4rem;
  text-align: center;
  padding-top: 2rem;
  color: #210f60;
  ${({ theme }) => theme.mq.lg`
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 2px;
    text-align: left;
    padding-top: 0;
  `}
  ${({ theme }) => theme.mq.xl`
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 2px;
    text-align: left;
    padding-top: 0;
  `}
`;

const Img = styled(Image)`
  width: 100%;
  // ${({ theme }) => theme.mq.sm`
  //   width: 100%;
  // `}
  // ${({ theme }) => theme.mq.md`
  //   width: 100%;
  // `}
  ${({ theme }) => theme.mq.lg`
    width:411px;
  `}
  ${({ theme }) => theme.mq.xl`
    width: 411px;
  `}
`;

const Link = styled(OutboundLink)`
  width: 100%;
  cursor: pointer;
`;

const ListContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 86px;
  padding-bottom: 65px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #00926E !important;
  }
  flex-direction: column;
  ${({ theme }) => theme.mq.md`
    flex-direction: column;
  `}
  ${({ theme }) => theme.mq.lg`
    flex-direction: row;
  `}
  ${({ theme }) => theme.mq.xl`
    flex-direction: row;
  `}
`;
export { Title, SubTitle, Img, ListContainer, Link };
